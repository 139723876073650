import React from 'react'
import useHttp from '../../hooks/use-http'
import GamesList from './GamesList'
import LoadingPanel from '../../component/LoadingPanel'
import GamesListImageItem from './GamesListImage'

function GamesPage({title,urlFetch,isList}) {

  
    const {data,loading,error} = useHttp(urlFetch)

  
  return (
    <>
    <h3 className='appear_animation'>{title}</h3>
    <div className='row '  >
        {loading && <LoadingPanel />}
        {data && <> {isList === true ?  <GamesListImageItem games={data} /> : <GamesList games={data} />}</>}
        </div>
    </>
  )
}

export default GamesPage