import React from 'react'
import logo from '../img/sniperondralogo.png'

function Logo() {
  return (      
    <div className='row' style={{paddingTop: '4em',paddingBottom: '4em'}}>
      <img srcSet={logo} className="rounded mx-auto d-block" alt="..." style={{width: '100px',height: 'auto'}} />
    </div>
  )
}

export default Logo