import React from 'react'
import SetupItem from './SetupItem'

function SetupItemList({items,title}) {
  return (
      <>
    <h5 className='appear_animation'>{title}</h5>
    <div className='row justify-content-center mt-5'>
    {items.map((item,index) => {
        return <SetupItem key={index} item={item} />
    })}

    </div>
    </>
  )
}

export default SetupItemList