import {  Route, Routes  } from 'react-router-dom'
import About from '../pages/about/About';
import GamesPage from '../pages/games/GamesPage';


import classes from './Content.module.css';


import Admin from '../pages/admin/Admin';
import Login from '../component/Login/Login';
import { useContext } from 'react';
import AuthContext from '../store/auth-context';
import * as URLConstants from '../utils/Constants'
import Game from '../pages/game/Game';
import Setup from '../pages/setup/Setup';

import MovieShowReviews from '../pages/movieshow/MovieShowReviews';
import MovieShowDetail from '../pages/movieshowdetail/MovieShowDetail';
import TestPage from '../pages/TestPage';
import Books from '../pages/books/Books';

function Content() {

  const authCtx = useContext(AuthContext)
 

  return (    
    <div className={classes.content + " h-100"} style={{ 

    }}>

         <Routes>
                   <Route path="/" element={<About />} />           
                   <Route path="/games" element={<GamesPage title={"Dohrané hry"} urlFetch={URLConstants.URL_FINISHED_GAMES} isList={true}/>} /> 
                   <Route path="/gamesplanned" element={<GamesPage title={"Plánované hry"} urlFetch={URLConstants.URL_PLANNED_GAMES} isList={false}/>} />    
                   <Route path="/gameswished" element={<GamesPage title={"Wishlist"} urlFetch={URLConstants.URL_WISHLIST_GAMES} isList={false} />} /> 
                   <Route path="/movie" element={<MovieShowReviews url={URLConstants.URL_MOVIES} title="Filmové recenze"  isMovie="movie"/>}  />             
                   <Route path="/tvshows" element={<MovieShowReviews url={URLConstants.URL_TV_SHOWS} title="Seriálové recenze"  isMovie="tvshow"/>}  /> 
                   <Route path="/books" element={<MovieShowReviews url={URLConstants.URL_BOOKS} title="Knížní recenze"  isMovie="book"/>}  /> 
                   <Route path="/bookspoints" element={<Books/>}  /> 
                   <Route path="/login" element={<Login />} /> 
                   <Route path="/test" element={<TestPage />} /> 
                   <Route path="/game/:id" element={<Game />} /> 
                   <Route path="/tvshow/:id" element={<MovieShowDetail url={URLConstants.URL_TV_SHOW_DETAIL} isMovie="tvshow" />} />
                   <Route path="/movie/:id" element={<MovieShowDetail url={URLConstants.URL_MOVIE_DETAIL} isMovie="movie" />} /> 
                   <Route path="/book/:id" element={<MovieShowDetail url={URLConstants.URL_BOOKS_DETAIL} isMovie="book" />} /> 
                   <Route path="/setup" element={<Setup />} /> 
                   {authCtx.isLoggedIn && (
                    <Route path="/admin" element={<Admin />} /> 
                   )}
               
                   
                           
        </Routes > 

             
      
     </div>
  )
}



export default Content