import React from 'react'

function SideBarList({items}) {

  const itemsSliced = items.slice(0, 3);


  return (<>
    {itemsSliced.map(item => (    
        <span className='d-block text-white' style={{fontSize: 'x-small'}} key={item.id}>{item.name}</span>
      ))}
      </>
  )
}

export default SideBarList