import React, { useState } from 'react'

import * as URLConstants from '../../utils/Constants'
import LoadingPanel from '../../component/LoadingPanel'
import useHttp from '../../hooks/use-http'
import AddSchedule from './forms/AddSchedule'
import RemoveSchedule from './forms/RemoveSchedule'


function AdminSchedulePanel() {

  const [update, setupdate] = useState(false)


    const {data,loading,error} = useHttp(URLConstants.URL_SCHEDULE,update)

  return (      
    <>
    <h2>Rozvh</h2>
    <div className='row justify-content-center' style={{marginTop: '2em',minHeight: '40vh'}}>
        {loading && <LoadingPanel />}
        {data && <>
          <AddSchedule data={data} setupdate={setupdate}/>
          <RemoveSchedule data={data} setupdate={setupdate}/>
        </>}

        {error && <>
        <h6>Error</h6>
        </>}
     
    </div>
    <hr />
    </>
  )
}

export default AdminSchedulePanel