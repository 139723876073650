import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import useHttp from '../../../hooks/use-http';
import AuthContext from '../../../store/auth-context';
import * as URLConstants from '../../../utils/Constants'

function AddReviewToGame({games,setUpdate}) {

  const gamesToDisplay = games.filter(it => it.id_type ===1)
  const navigate = useNavigate();
  const reviewInputRef = useRef();
  const idInputRef = useRef();
    const [gameId, setgameId] = useState(1)

    const [enteredGameId, setEnteredGameId] = useState(1)
    const scoreInputRef = useRef();
    const [gameType, setGameType] = useState(1)
    const [enteredScore, setEnteredScore] = useState("")
    const authCtx = useContext(AuthContext)
    const [enteredReview, setEnteredReview] = useState("")
    const [errorMsg, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')  


    useEffect(() => {
      if(gamesToDisplay){        
        const game =  gamesToDisplay[0];
        if(game){
          setgameId(game.id)
          setEnteredReview(game.review)
          setEnteredScore(game.score)
        }
      }
    
      
    }, [])

   

    useEffect(() => {
      const game = games.find(it => it.id == gameId)
      if(game){
        setEnteredReview(game.review)
        setEnteredScore(game.score)
      }
      
    }, [gameId])
    
    const scoreInputChangeHandler = event => {
      setEnteredScore(event.target.value);
  };

  const idChangeHandler = event => {
    setEnteredGameId(event.target.value);
};

    const formSubmissionHandler = event => {
        event.preventDefault();

    
        if(gameId !==0){

          if(enteredScore < 0 || enteredScore > 100){
            setErrorMessage("Hodnocení musí být v rozsahu 0-100");
            return;
        }

            const game = { 
              id: Number(enteredGameId),
              review: enteredReview,
              score: enteredScore
            };

    
      
            
            const token = 'Bearer ' + authCtx.token;
            fetch(URLConstants.URL_GAMES_UPDATE,
            {
              method: 'POST',
              body: JSON.stringify(game),
              headers: {
                'Content-Type':'application/json',
                'Authorization': token
              }
            }
            ).then(res => {
              if(res.ok) {
                return res.json().then((data) => {
                    setOkMessage(data.message)
                    setUpdate(previousState => !previousState)
                })
              }else{
                if(res.status == 401){
                  navigate("/login")
                }
                return res.json().then((data) => {
                  setErrorMessage(data.message)
                })
              }
            });
        }
        setgameId(0)
    }; 

    const reviewInputChangeHandler = event => {
      setEnteredReview(event.target.value);
  };

  function handleChange(event){
    setgameId(event.target.value);
  }

    return (
      <div className='col-xl-6'>
      <h5>Upravit hru</h5>
              <form onSubmit={formSubmissionHandler}>


                  <label htmlFor="gametypes" className='d-block'>Hra</label>
          <select className='d-block my-2' name="gametypes" id="gametype"  onChange={handleChange} >
            {gamesToDisplay && <>
          {gamesToDisplay.map((item,index) => (
            <option key={index} value={item.id}>{item.name}</option>
            ))}</>}
          
          </select>

          <label className='d-block' htmlFor='Id'>Id</label>
        <textarea ref={idInputRef}  onChange={idChangeHandler} value={enteredReview} rows="4" cols="50" name="Id" form="usrform" />

          <label className='d-block' htmlFor='name'>Recenze</label>
        <textarea ref={reviewInputRef}  onChange={reviewInputChangeHandler} value={enteredReview} rows="4" cols="50" name="comment" form="usrform" />

        <label className='d-block' htmlFor='score'>Hodnocení</label>
        <input ref={scoreInputRef} type="number" placeholder='0-100' id='score' onChange={scoreInputChangeHandler} value={enteredScore} />
  
          <button type="submit" className=" d-block my-2 btn btn-success">Přidat/upravit</button>
      </form>
      <p style={{color: 'red'}}>{errorMsg}</p>
    <p style={{color: 'green'}}>{okMessage}</p>
      </div>
    )
}

export default AddReviewToGame