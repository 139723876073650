
const BASE_URL = "https://sniperondra.cz/"
//const BASE_URL = "http://localhost:8000/"



export const URL_LOGIN = BASE_URL + "/api/admin/login"

export const URL_GAMES = BASE_URL + "api/games"
export const URL_GAMES_DELETE = BASE_URL + "api/games/delete/"
export const URL_GAMES_UPDATE = BASE_URL + "api/games/update"
export const URL_FINISHED_GAMES = BASE_URL + "api/games/finished"
export const URL_GAME_DETAIL = BASE_URL + "api/games/game/"
export const URL_PLANNED_GAMES = BASE_URL + 'api/games/planned';
export const URL_WISHLIST_GAMES = BASE_URL + 'api/games/wishlist';
export const URL_SCHEDULE = BASE_URL + 'api/schedule';
export const URL_SCHEDULE_DELETE = BASE_URL + 'api/schedule/delete/';


export const URL_MOVIES = BASE_URL + "api/movies"
export const URL_MOVIES_DELETE = BASE_URL + 'api/movies/delete/';
export const URL_MOVIE_DETAIL = BASE_URL + "api/movies/movie/"

export const URL_TV_SHOWS = BASE_URL + "api/tvshows"
export const URL_TV_SHOWS_DELETE = BASE_URL + 'api/tvshows/delete/';
export const URL_TV_SHOW_DETAIL = BASE_URL + "api/tvshows/tvshow/"

export const URL_BOOKS = BASE_URL + "api/books"
export const URL_BOOKS_DELETE = BASE_URL + 'api/books/delete/';
export const URL_BOOKS_DETAIL = BASE_URL + "/api/books/book/"

