
import React, { useState } from 'react'
import SearchText from '../../component/UI/SearchText/SearchText'
import GamesListItem from './GamesListItem'

function GamesList({games}) {
    const [searchedText, setSearchedText] = useState("")
    const gamesToDisplay = games.sort((a, b) => (a.name > b.name) ? 1 : -1)


  return (<>
      <SearchText searchedText={searchedText} setSearchedText={setSearchedText}/>
    <div className='row' style={{marginTop: '2em'}}>

    {gamesToDisplay.filter(item => item.name.toLowerCase().includes(searchedText)).map((item,index) => (
        <>
            {searchedText.length < 2 ? <div className='col-sm-4'>
            <GamesListItem key={index} item = {item}  />
            </div> : <>
            <GamesListItem key={index} item = {item}  />
            </>}
        </>
       ))}
    </div>
    </>
  )
}

export default GamesList