


import React, {useContext, useRef, useState } from 'react'
import AuthContext from '../../../store/auth-context';
import * as URLConstants from '../../../utils/Constants'
import { useNavigate } from 'react-router';

function AddTvShowForm({data,setupdate}) {

  const authCtx = useContext(AuthContext)
  const nameInputRef = useRef("")
  const scoreInputRef = useRef();
  const reviewInputRef = useRef("")
  const navigate = useNavigate()

  const imgInputRef = useRef("")
  const [enteredImage, setEnteredImage] = useState("")
  const [enteredScore, setEnteredScore] = useState("")
  const [enteredMovieName, setenteredMovieName] = useState("")
  const [enteredMovieReview, setMovieReview] = useState("")
  const [errorMsg, setErrorMessage] = useState('')
  const [okMessage, setOkMessage] = useState('')  

  const [image, setImage] = useState([])
  const [imageSRC, setImageSRC] = useState("")


function onImageChange(e){
   setImage(e.target.files[0]);
   const imageUrl = URL.createObjectURL(e.target.files[0])
   setImageSRC(imageUrl)
   console.log(setImage);
}   

  const submitHandler = event => {
      event.preventDefault();
      const enteredName = nameInputRef.current.value;
      const enteredReview = reviewInputRef.current.value;

      
      if(enteredScore < 0 || enteredScore > 100){
        setErrorMessage("Hodnocení musí být v rozsahu 0-100");
        return;
    }

      if(enteredName && enteredReview){


        const formData = new FormData();
  
        formData.append("fileimage",image,image.name); 
        formData.append("name",enteredName)
        formData.append("review",enteredReview)
        formData.append("score",enteredScore)

          setErrorMessage("")
         /* const movieObj = {
              name: enteredName,
              review: enteredReview,
              score: enteredScore
          } */
          const token = 'Bearer ' + authCtx.token;


          fetch(URLConstants.URL_TV_SHOWS,
              {
                method: 'POST',
                body: formData,
                headers: {
                  'Authorization': token
                }
              }
              ).then(res => {
                if(res.status === 401){
                  navigate("/login")
                }
                if(res.ok) {
                  return res.json().then((data) => {
                      setOkMessage(data.message)
                      setenteredMovieName("")
                      setMovieReview("")
                      setupdate(previousState => !previousState)

                  })
                }else{
                  if(res.status == 401){
                    navigate("/login")
                  }
                  return res.json().then((data) => {
          
                  })
                }
              }).catch(exception => {
            
              });
      }else{
          setErrorMessage("Vyplňte všechna pole")
      }

  }

  const nameChange = event => {
      setenteredMovieName(event.target.value)
  }
  const scoreInputChangeHandler = event => {
    setEnteredScore(event.target.value);
};

  const reviewChange = event => {
      setMovieReview(event.target.value)
  }

  const imgChange = event => {
    setEnteredImage(event.target.value)
}

return (
  <>
  <h5>Přidat serialovou recenzi</h5>
  <form onSubmit={submitHandler}>
      <label className='d-block' htmlFor='name'>Název seriálu</label>
      <input  className='d-block my-2' ref={nameInputRef} type="text" id='name' onChange={nameChange} value={enteredMovieName} />
      <label className='d-block' htmlFor='score'>Hodnocení</label>
      <input ref={scoreInputRef} type="number" placeholder='0-100' id='score' onChange={scoreInputChangeHandler} value={enteredScore} />
      <label className='d-block' htmlFor='name'>Recenze</label>
      <textarea className='d-block mb-2' ref={reviewInputRef}  onChange={reviewChange} value={enteredMovieReview} rows="4" cols="50" name="comment" form="usrform" />

      <label className='d-block' htmlFor='imgChoose'>Nahrát obrázek</label>
      <input className='d-block' type="file" id='imgChoose' accept='image/*' onChange={onImageChange} />
      {imageSRC && <img className='d-block' srcSet={imageSRC} alt="" style={{width: '320px', height: '180px'}} />}
   

      <button type='submit' className='btn btn-success mt-2'>Uložit recenzi</button>
  </form>
  <p style={{color: 'red'}}>{errorMsg}</p>
  <p style={{color: 'green'}}>{okMessage}</p>
  </>
)


}

/**
 *        
 */

export default AddTvShowForm