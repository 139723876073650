import React from 'react'

function SearchText({searchedText, setSearchedText}) {

    function onSearchHandle(event){
        setSearchedText(event.target.value.toLowerCase())
      }

  return (
    <div className='col-2'>
        <span>Vyhledat hru: </span>
        <input type="text" onChange={onSearchHandle} value={searchedText} />
        </div>
  )
}

export default SearchText