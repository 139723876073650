import React from 'react'
import styles from './GamesListItem.module.css'


function GamesListItem({item}) {
    return (
      <span className="text-center d-block"  >{item.name}</span>
    )
}

export default GamesListItem