import React from 'react'
import LoadingPanel from '../../component/LoadingPanel'
import useHttp from '../../hooks/use-http'
import MovieShowList from './MovieShowList'

function MovieShowReviews({url,title,isMovie}) {

    const {data,loading,error} = useHttp(url)
    

  
    return (
      <>
      <h3 className='appear_animation'>{title}</h3>
    
      {loading && <LoadingPanel />}
      {data && <MovieShowList movies={data} isMovie={isMovie} />}
      </>
    )
  }

export default MovieShowReviews