import React from 'react'

function LoadingPanel() {
  return (
    <div className='row ' >
        <h5 style={{textAlign: 'center'}}>Načítám...</h5>
        
        </div>
  )
}

export default LoadingPanel