import React from 'react'
import LoadingPanel from '../../component/LoadingPanel'
import useHttp from '../../hooks/use-http'

import { useNavigate } from 'react-router-dom';

import { getTvShowById } from '../movieshow/TvShowDatabase';
import { getMovieById } from '../movieshow/MovieDatabase';
import { getScoreTest } from '../../utils/Utils';

function MovieShowDetail({url,isMovie}) {

    let urlPage = window.location.pathname;
    const navigate = useNavigate();

    const lastSegment = urlPage.substring(urlPage.lastIndexOf("/") + 1);

    const urlAddress = url +  lastSegment

    const {data,loading,error} = useHttp(urlAddress)

    let hodnoceni = '0'
 
    let img = ''
    let review = ''
    let name = ''
    if(data){
      img = getMovieById(data.img)      
        review = data.review
         name = data.name
         hodnoceni = getScoreTest(data.score)
    }
    
    console.log(isMovie);
  
  return (
    <>
    <div className='row '  >
  
    {loading && <LoadingPanel />}
        {data && <>
        <div>
       
      <button onClick={() => navigate(-1)} className="btn btn-link my-2">
        ← Zpět na seznam 
        </button>
      </div>
      <div className='row mt-3'>
       <h5 className='text-white ' style={{float: 'left', display: 'block'}}>{name}</h5>
       </div>
       <div>
          {isMovie === 'book' ? 
             <img alt='' srcSet={img} className="border" style={{height: '300px',width: '210px', float: 'left', objectFit: 'contain'}}       />
             : <img alt='' srcSet={img} className="border" style={{maxHeight: '280px',width: '320px', float: 'left', objectFit: 'contain'}} /> 
      
          }
          
  
       
       <div className='row mt-2'>
       <h6 className='text-white ' style={{float: 'left', display: 'block'}}>Hodnocení: {hodnoceni}</h6>
       </div>

       <div className='row mt-2'>
       

       <h6 className='text-white ' style={{float: 'left', display: 'block'}}>Recenze</h6>
       
        <p className='text-white px-3' > 

          {review}
        
          </p> 

          
          </div>
     </div>

        </>
        
        }

    {error && <h6>Error</h6>}
        </div>
        </>
  )
}

export default MovieShowDetail