import React, { useContext, useEffect, useState } from 'react'

import AuthContext from '../../../store/auth-context';
import * as URLConstants from '../../../utils/Constants'
import { useNavigate } from 'react-router';
function RemoveSchedule({data,setupdate}) {

    const [enteredGameId, setenteredGameId] = useState(0)
  //  const [weekendData, setweekendData] = useState(0)
  //  const [dayData, setdayData] = useState(0)
  const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const [errorMsg, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')  

    const weekendData = data.filter(item => item.id_type == 1)
    const dayData  = data.filter(item => item.id_type == 2)



  

    function deleteScheduleFromServer(id){
  
        if(id !==0){

            
            const token = 'Bearer ' + authCtx.token;

      
            fetch(URLConstants.URL_SCHEDULE_DELETE  + id,
            {
              method: 'DELETE',
              headers: {
                'Content-Type':'application/json',
                'Authorization': token
              }
            }
            ).then(res => {
              if(res.ok) {
                return res.json().then((data) => {
                    setOkMessage(data.message)
                    setupdate(previousState => !previousState)
                  })
              }else{
                if(res.status == 401){
                  navigate("/login")
                }
                return res.json().then((data) => {
                  setErrorMessage(data.message)
                   setupdate(previousState => !previousState)
                })
              }
            });
        }
        setenteredGameId(0)
    };


  function handleChange(event){
    setenteredGameId(event.target.value);
  }

    return (
      <div className='col-xl-6'>
      <h5>Odebrat hru</h5>
      <p style={{color: 'red'}}>{errorMsg}</p>
    <p style={{color: 'green'}}>{okMessage}</p>

     <div className='row'>
         <div className='col-xl-6'>
     <table className="table table-dark">
              <thead>
                <tr>                  
                  <th scope="col">Všední den</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
              {dayData && <>
              {dayData.map((item,index) => (
                  <tr>
                  <td>{item.name}</td>
                  <td className='justify-content-end'><button type="button" onClick={() => deleteScheduleFromServer(item.id)} className="btn btn-danger">Odebrat</button></td>
                  </tr>
                ))}  
                </>}
              </tbody>
          </table>
          </div> 
          <div className='col-xl-6'>
         
          <table className="table table-dark">
              <thead>
                <tr>                  
                  <th scope="col">Víkend</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
              {weekendData && <>
              {weekendData.map((item,index) => (
                  <tr>
                  <td>{item.name}</td>
                  <td className='text-right'><button type="button" className="float-right btn btn-danger" onClick={() => deleteScheduleFromServer(item.id)}>Odebrat</button></td>
                  </tr>
                ))}  
                </>
                } 
              </tbody>
          </table>
          </div>     
        
     </div> 
    

      </div>
    )
} //      <option key={index} value={item.id}>{item.name}</option>

export default RemoveSchedule