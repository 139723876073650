import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../UI/Card/Card';
import classes from './Login.module.css';
import Button from '../UI/Button/Button';
import AuthContext from '../../store/auth-context';
import * as URLConstants from '../../utils/Constants'

const Login = (props) => {  
  
  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState();
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState();
  const [formIsValid, setFormIsValid] = useState(false);

  const navigate = useNavigate();
  
  const [isLogin, setisLogin] = useState(false)

  const authCtx = useContext(AuthContext);


  const submitHandler = (event) => {
    event.preventDefault();


    
    if(isLogin){

    }else{
      fetch(URLConstants.URL_LOGIN,
      {
        method: 'POST',
        body: JSON.stringify({
          name: enteredEmail,
          password: enteredPassword
        }),
        headers: {
          'Content-Type':'application/json'
        }
      }
      ).then(res => {
        if(res.ok) {
          return res.json().then((data) => {
     
            const expTime = new Date((new Date().getTime() + (data.expires_in * 1000)))
            authCtx.login(data.access_token,expTime.toISOString())
            navigate("/admin")
          })
        }else{
          return res.json().then((data) => {
      
          })
        }
      });
    }
    
  };


  useEffect(() => {
    const idnetifier = setTimeout(() =>{

      setFormIsValid(
         enteredPassword.trim().length > 6
      );

    },500);


    return () => {
   
      clearTimeout(idnetifier)
    }
  }, [])

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);

    setFormIsValid(
       enteredPassword.trim().length > 6
    );
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);

    setFormIsValid(
      event.target.value.trim().length > 6
    );
  };



  const validatePasswordHandler = () => {
    setPasswordIsValid(enteredPassword.trim().length > 6);
  };



  return (
    <Card className={classes.login}>
      <form onSubmit={submitHandler}>
        <div
          className={`${classes.control} ${emailIsValid === false ? classes.invalid : ''
            }`}
        >
          <label htmlFor="email">E-Mail</label>
          <input
            type="text"
            id="email"
            value={enteredEmail}
            onChange={emailChangeHandler}
           
          />
        </div>
        <div
          className={`${classes.control} ${passwordIsValid === false ? classes.invalid : ''
            }`}
        >
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={enteredPassword}
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
          />
        </div>
        <div className={classes.actions}>
          <Button type="submit" className={classes.btn} disabled={!formIsValid}>
            Login
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default Login;
