import React, { useState } from 'react'
import GameModal from '../../component/GameModal';
import GameItemImage from './GameItemImage';

function GamesListImage({games}) {
  const [searchedText, setSearchedText] = useState("")
    const gamesToDisplay = games.sort((a, b) => (a.game_order < b.game_order) ? 1 : -1)

    function onSearchHandle(event){
      setSearchedText(event.target.value.toLowerCase())
    }


    const [isOpen, setIsOpen] = useState(false)
    const [gameToShow, selectGameToshow] = useState(false)
    

    window.onclick = function(event) {

      if (!event.target.matches('.imgclick')) {
        setIsOpen(false)
      }
    }
  let filteredGames=[]

  if(searchedText.length > 1){
    filteredGames = (
      <>
       {gamesToDisplay.filter(item => item.name.toLowerCase().includes(searchedText)).map((item,index) => (
         <GameItemImage game = {item}  setIsOpen={setIsOpen} selectGameToshow={selectGameToshow}/>
       ))}
    </>
     ) 
  }else{
    filteredGames = (
      <>
       {gamesToDisplay.map((item,index) => (
         <GameItemImage game = {item}  setIsOpen={setIsOpen} selectGameToshow={selectGameToshow}/>
       ))}
      </>
     ) 
  }
  return (
    <div>
      <div className='col-2'>
      <span>Vyhledat hru: </span>
    <input type="text" onChange={onSearchHandle} value={searchedText} />
    </div>
       {filteredGames}
    </div>
  )
}
/*
 {gamesToDisplay.map(game => {
          return <GameItemImage game = {game} setIsOpen={setIsOpen} selectGameToshow={selectGameToshow} />
        })}
*/
export default GamesListImage