import React, { useState } from 'react'

import * as URLConstants from '../../utils/Constants'
import LoadingPanel from '../../component/LoadingPanel'
import useHttp from '../../hooks/use-http'

import AddMovieForm from './forms/AddMovieForm'
import AddTvShowForm from './forms/AddTvShowForm'
import RemoveTvShowForm from './forms/RemoveTvShowForm'
import RemoveMovieForm from './forms/RemoveMovieForm'

import AddBookForm from './forms/AddBookForm'
import RemoveBookForm from './forms/RemoveBookForm'


function AdminMoviesPanel() {

  const [update, setupdate] = useState(false)
  const [updateTv, setupdateTv] = useState(false)
  const [updateBooks, setupdateBooks] = useState(false)

    const {data: data2,loading,error} = useHttp(URLConstants.URL_TV_SHOWS,updateTv)
    const {data: data,loading: loadingMovies,error: errorMovies} = useHttp(URLConstants.URL_MOVIES,update)
    const {data: dataBook,loading: loadingBook,error: errorBook} = useHttp(URLConstants.URL_BOOKS,updateBooks)

    

  return (      
    <>
    <h2>Recenze</h2>
    <div className='row justify-content-center' style={{marginTop: '2em',minHeight: '40vh'}}>
    
    {loading && <LoadingPanel />}
        {data && <>
          <div className='col-xl-4'>
          <AddMovieForm movies={data} setupdate={setupdate}/>
          <hr />
          <RemoveMovieForm  movies={data} setupdate={setupdate}/>
          </div>
   
        </>}

        {loadingMovies && <LoadingPanel />}
        {data && <>
          <div className='col-xl-4'>
          <AddTvShowForm data={data2} setupdate={setupdateTv}/>
          <hr />
          <RemoveTvShowForm movies={data2} setupdate={setupdateTv}/>
          </div>
        </>}
        {error && <>
        <h6>Error</h6>
        </>}

        {loadingMovies && <LoadingPanel />}
        {data && <>
          <div className='col-xl-4'>
          <AddBookForm data={dataBook} setupdate={setupdateBooks}/>
          <hr />
          <RemoveBookForm movies={dataBook} setupdate={setupdateBooks}/>
          </div>
        </>}
        {error && <>
        <h6>Error</h6>
        </>}
     
    </div>
    <hr />
    </>
  )
}

/**
 *     
 */

export default AdminMoviesPanel