import React from 'react'
import LoadingPanel from '../../component/LoadingPanel'
import useHttp from '../../hooks/use-http'
import * as URLConstants from '../../utils/Constants'
import { useNavigate } from 'react-router-dom';
import { getImageUrl, getLaswWordFromstring, getScoreTest, removeLaswWordFromstring } from '../../utils/Utils'

function Game() {

    let urlPage = window.location.pathname;
    const navigate = useNavigate();

    const lastSegment = urlPage.substring(urlPage.lastIndexOf("/") + 1);

    const url = URLConstants.URL_GAME_DETAIL +  lastSegment
    const {data,loading,error} = useHttp(url)
    let img = ''
    let hodnoceni = ''
    let name = ''
    if(data){
        img = getImageUrl(data.id)
        hodnoceni = getScoreTest(data.score) 
        name = data.name
    }
    
    function navigateBack(){

    }
  
  return (
    <>
    <div className='row '  >
  
    {loading && <LoadingPanel />}
        {data && <>
        <div>
      <button onClick={() => navigate(-1)} className="btn btn-link my-2">← Zpět na seznam her</button>
      </div>
       <div>
       <img srcSet={img} className="border" style={{maxHeight: '280px',width: '210px', float: 'left'}} />
       <div className='row mt-3'>
       <h5 className='text-white ' style={{float: 'left', display: 'block'}}>{name}</h5>
       </div>
       
       <div className='row mt-2'>
       <h6 className='text-white ' style={{float: 'left', display: 'block'}}>Hodnocení: {hodnoceni}</h6>
       </div>
       <div className='row mt-2'>
       

       <h6 className='text-white ' style={{float: 'left', display: 'block'}}>Recenze</h6>
       
        <p className='text-white px-3' > 
        
          {data.review}
          </p> 

          
          </div>
     </div>

        </>
        
        }
        </div>
        </>
  )
}

export default Game