

import React from 'react'
import { useNavigate } from 'react-router'
import { getImageUrl, getScoreTest} from '../../utils/Utils'
import styles from './GameItemImage.module.css'


function GameItemImage({game,setIsOpen,selectGameToshow}) {

  const navigation = useNavigate();
  let imageSrc = getImageUrl(game.id)

  if(game.id === 11856){
    imageSrc = "https://i.imgur.com/1rCTLdT.jpeg"
  }

  function goToGamePage(){
    navigation('/game/' + game.id);
  }

  return (
    <div className={styles.containerimg + ' imgclick'} onClick={goToGamePage} style={{display: 'inline-block'}}>
    <img srcSet={imageSrc} className="border" />
        <div className={styles.overlay  + ' imgclick'}>
            <div className={styles.text}>{game.name}</div>
            <div className={styles.textrecenze}>{getScoreTest(game.score)}</div>
        </div>
    </div>
  )
}

export default GameItemImage