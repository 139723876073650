import React, { useState } from 'react'

function TestPage() {

    
    const [image, setImage] = useState([])
    const [imageSRC, setImageSRC] = useState("/storage/app/public/images/venom.jpg")

 
 function onImageChange(e){
     setImage(e.target.files[0]);
     const imageUrl = URL.createObjectURL(e.target.files[0])
     setImageSRC(imageUrl)
     console.log(setImage);
 }   

 const onFileUpload = () => {

 // Create an object of formData
 const formData = new FormData();
    
 // Update the formData object
       formData.append(
          "fileimage",
          image,
          image.name
        ); 

        formData.append("name","Honza")
    

        const bodyObj = {
          test: "Ahoj",
          file: formData
        }

  

        fetch("http://localhost:8000/api/tvshows/uploadfile",
          {
            method: 'POST',
            body: formData,
            headers: {
        
            }
          }
          ).then(res => {
            if(res.ok) {
              return res.json().then((data) => {

              })
            }else{
              return res.json().then((data) => {
      
              })
            }
          }).catch(exception => {
        
          });

 }

  return (
    <div className='row justify-content-center'>
        <input type="file" accept='image/*' onChange={onImageChange} />
        {imageSRC && <img  srcSet={imageSRC} alt="" style={{width: '320px', height: '180px'}} />}
        <button className='btn btn-success' onClick={onFileUpload}>
                  Upload!
                </button>
    </div>
  )
}

export default TestPage