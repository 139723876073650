
import React, { useContext, useRef, useState } from 'react'

import AuthContext from '../../../store/auth-context';
import * as URLConstants from '../../../utils/Constants'
import { useNavigate } from 'react-router';

function AddSchedule({setupdate}) {
    const nameInputRef = useRef();
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const [enteredGame, setenteredGame] = useState('')
    const [errorMsg, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')  
    const [enteredType, setenteredType] = useState(1)

    const nameInputChangeHandler = event => {
        setenteredGame(event.target.value);
    };

    function handleChange(event){
        setenteredType(event.target.value);
    }


    const formSubmissionHandler = event => {
        event.preventDefault();
        
        const enteredValue = nameInputRef.current.value;
        if(enteredValue){
            setErrorMessage("");
            const token = 'Bearer ' + authCtx.token;
            fetch(URLConstants.URL_SCHEDULE,
            {
              method: 'POST',
              body: JSON.stringify({
                name: enteredGame,
                id_type: enteredType
              }),
              headers: {
                'Content-Type':'application/json',
                'Authorization': token
              }
            }
            ).then(res => {
              if(res.status === 401){
                navigate("/login")
              }
              if(res.ok) {
                return res.json().then((data) => {
                    setOkMessage(data.message)
                    setupdate(previousState => !previousState)
                })
              }else{
                if(res.status == 401){
                  navigate("/login")
                }
                return res.json().then((data) => {
        
                })
              }
            }).catch(exception => {
          
            });
            
        

                
        }else{
            setErrorMessage("Nevyplněn název hry");
        }


        setenteredGame('')
    };

  return (
      <div className='col-xl-6'>
        
    <h5>Přidat hru do rozvrhu</h5>
    <form onSubmit={formSubmissionHandler}>
        <label className='d-block' htmlFor='name'>Název hry</label>
        <input ref={nameInputRef} type="text" id='name' onChange={nameInputChangeHandler} value={enteredGame} />

        <label className='d-block' htmlFor="gametypes">Kdy</label>
 
<select name="gametypes" id="gametype" className='d-block my-2'  onChange={handleChange} >
  <option value={1}>Víkend</option>
  <option value={2}>Všední dny</option>
</select>

        <button type="submit" className="btn btn-success">Přidat</button>
    </form>
    <p style={{color: 'red'}}>{errorMsg}</p>
    <p style={{color: 'green'}}>{okMessage}</p>
    </div>
  )
}

export default AddSchedule