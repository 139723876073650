
import React, {  useState } from 'react'

import MovieListItem from './MovieShowListItem';

function MovieShowList({movies,isMovie}) {

  const [searchedText, setSearchedText] = useState("")
    const moviesToDisplay = movies.sort((a, b) => (a.id > b.id) ? 1 : -1)

    function onSearchHandle(event){
      setSearchedText(event.target.value.toLowerCase())
    }

  const filteredMovies = moviesToDisplay.filter(item => item.name.toLowerCase().includes(searchedText))

 

  let filteredGames=[]

  if(searchedText.length > 1){
    filteredGames = (
      <>
       {filteredMovies.map((item,index) => (
         <MovieListItem key={item.id} movie = {item} isMovie = {isMovie}/>
       ))}
    </>
     ) 
  }else{
    filteredGames = (
      <>
       {filteredMovies.map((item,index) => (
         <MovieListItem key={item.id} movie = {item} isMovie = {isMovie} />
       ))}
   </>
     ) 
  }
  

  return (
    <div>
      <div className='col-2'>
      <span>Vyhledat film: </span>
    <input type="text" onChange={onSearchHandle} value={searchedText} />
    </div>
       {filteredGames}
    </div>
  )

}
//  <span style={{textAlign: 'center',display: 'block'}} key={item.id}>{item.name}</span>
//  <input type="text" value={searchedText} onChange={handleChange} />

export default MovieShowList