import React from 'react'
import { setupData,setupDataOthers } from './SetupData'
import SetupItemList from './SetupItemList'


const Setup = () => {
  return (
    <>
    <SetupItemList items={setupData} title="PC Setup" />
        <hr />
    <SetupItemList items={setupDataOthers}  />
  </>
  )
}


export default Setup