import { createContext, useState, useEffect, useCallback } from "react";

let logoutTimer;

const AuthContext = createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {}
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime()

    const remainingDuration = adjExpirationTime - currentTime

    return remainingDuration;
};

const retrievedStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');

    const remaiiningTime = calculateRemainingTime(storedExpirationDate);
    if(remaiiningTime <= 0){
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        return null;
    }

    return {
        token: storedToken,
        duration: remaiiningTime
    }
}

export const AuthContextProvider = (props) => {
    const tokenData = retrievedStoredToken();
    let initialToken; 
    if(tokenData){
        initialToken = tokenData.token;
    }
    const [token, setToken] = useState(initialToken)

    const userIsLoggedIn = !!token;

    const loginHandler = (token,expirationTime) => {
        setToken(token);
        localStorage.setItem('token',token);
        localStorage.setItem('expirationTime',expirationTime);

        const remainingTime = calculateRemainingTime(expirationTime)

        logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

    const logoutHandler = useCallback(() => {
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        if(logoutTimer){
            clearTimeout(logoutTimer)
        }
    },[]);

    useEffect(() => {
      if(tokenData){
        logoutTimer = setTimeout(logoutHandler, tokenData.duration);
      }
    }, [tokenData])
    

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    }


    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;