import React from 'react'
import sniperLogo from '../../img/sniperondralogo.png'


function About() {
  return (
    <div className="row align-items-center" style={{height: '80%'}}>
    <div className="col">
   
    <div className='row justify-content-center'>
        <div className='col-xl-5 '>
        <h3 className='appear_animation'>INFO</h3>
            <p className='listitem'>
            Narozen 19.2.1991 (takže už starší kousek) jméno je součástí mé přezdívky a bydlím v Ostravě. Hráč PS4 a PC her (nových i starších) filmový a seriálový nadšenec + amatérský redaktor článků a scénarista občasný čtenář příležitostný sportovec (kolektivní sporty) rekreační lukostřelec a jezdec na kole začínající cestovatel

I can speak English, but it is not my native language, so be forgiving.
            </p>
            <p className='listitem'>
            I can speak English, but it is not my native language, so be forgiving.
            </p>
        </div>
        <div className='col-xl-2 mx-2'>
            <img srcSet={sniperLogo} alt="" />
        </div>
    </div>
   </div>
   </div>
  )
}

export default About