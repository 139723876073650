import React, { useContext } from 'react'
import * as URLConstants from '../../utils/Constants'
import { useState } from 'react'
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router';


function RemoveGamePlanItem({game, setUpdate}) {


    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    
    const [errorMsg, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')  

    const handleDelete = event => {
        event.preventDefault();

    
        if(game.id !==0){


            
            const token = 'Bearer ' + authCtx.token;
            fetch(URLConstants.URL_GAMES_DELETE + game.id,
            {
              method: 'DELETE',
              game,
              headers: {
                'Content-Type':'application/json',
                'Authorization': token
              }
            }
            ).then(res => {
              if(res.ok) {
                return res.json().then((data) => {
                    setOkMessage(data.message)
                    setUpdate(previousState => !previousState)
                })
              }else{
                if(res.status == 401){
                  navigate("/login")
                }
                return res.json().then((data) => {
                  setErrorMessage(data.message)
                })
              }
            });
        }
      
    };



  return (
    <tr className="table-dark">
      <td>{game.name}</td>

      <td>
        <button className='btn btn-danger' onClick={handleDelete}>Smazat</button>
      </td>
    </tr>
  )
}

export default RemoveGamePlanItem