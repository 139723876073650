import React from 'react'

function SetupItem({item}) {
    return <div className='col'>
    <div className='d-flex justify-content-center'>
    <img srcSet={item.img} alt="" style={{width: '100px'}} />
    </div>
    <h6 className='text-center' >{item.name}</h6>
  </div>
}

export default SetupItem