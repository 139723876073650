import { Button } from 'bootstrap'
import { Dropdown } from 'bootstrap'
import React, { useState } from 'react'
import { Navbar, NavDropdown, NavItem } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import styles from './NavigationMenuMobile.module.css'



function NavigationMenuMobile() {

    const [gameOpen, setgameOpen] = useState(false)

    function toggleGameMenu(){
           if(gameOpen) {
               setgameOpen(false)
           }else{
               setgameOpen(true)
           }
    }

    function openGameMenu(){
            setgameOpen(true)   
     }

     function closeGameMenu(){
        setgameOpen(false)   
    }
 


      return (
        <Navbar collapseOnSelect expand="lg" className='flex-column'>
        <NavLink className={styles.navlink  } to="/" >INFO</NavLink>
        <NavLink className={styles.navlink  } to="/setup" >SETUP</NavLink>
        <button onClick={toggleGameMenu} className={styles.navlink_button}>HRY</button>
        {gameOpen && <>
            <NavLink className={styles.navlink_smaller} to="/games">DOHRANÉ</NavLink>
            <NavLink className={styles.navlink_smaller} to="/gamesplanned">PLÁNOVANÉ</NavLink>
       
        </> }
    
        <NavLink className={styles.navlink} to="/movie">FILMOVÉ RECENZIČKY</NavLink>
        <NavLink className={styles.navlink} to="/tvshows">SERIALOVÉ RECENZIČKY</NavLink>
        <NavLink className={styles.navlink } to="/books">KNÍŽNÍ RECENZIČKY</NavLink>
        </Navbar>    
      )
 

}

export default NavigationMenuMobile