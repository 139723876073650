import React from 'react'
import { useNavigate } from 'react-router';

import { getMovieById } from './MovieDatabase';
import { getTvShowById } from './TvShowDatabase';
import styles from './MovieListItem.module.css'
import {  getScoreTest} from '../../utils/Utils'


function MovieShowListItem({movie,isMovie}) {
  const navigation = useNavigate();
  
  const scoreText = getScoreTest(movie.score)
 
    function openModal(){
        const url = '/' + isMovie + '/' + movie.id
        navigation(url);
    }

    const url = getMovieById(movie.img);
 
    console.log(url);
     

  if(isMovie !== "book")  {
    return (
      <div className={styles.containerimg + ' imgclick'} onClick={openModal} style={{display: 'inline-block'}}>
      <img  srcSet={url} className="border" width='320px' height='180px' />
          <div  className={styles.overlay  + ' imgclick'}>
              <div  className={styles.text}>{movie.name}</div>
              <div  className={styles.textrecenze}>{scoreText}</div>
          </div>
      </div>
    )
  }
  else{
    return (
      <div className={styles.containerimg + ' imgclick'} onClick={openModal} style={{display: 'inline-block'}}>
      <img  srcSet={url} className="border" width='210px' height='300px' />
          <div  className={styles.overlay  + ' imgclick'}>
              <div  className={styles.text}>{movie.name}</div>
              <div  className={styles.textrecenze}>{scoreText}</div>
          </div>
      </div>
    )
  }
}

export default MovieShowListItem