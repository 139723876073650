import React, { useState } from 'react'
import AddFinishedGame from './forms/AddFinishedGame'
import RemoveFinishedGame from './forms/RemoveFinishedGame'
import * as URLConstants from '../../utils/Constants'
import LoadingPanel from '../../component/LoadingPanel'
import useHttp from '../../hooks/use-http'
import AddPlannedGame from './forms/AddPlannedGame'
import AddWishListgame from './forms/AddWishListGame'
import AddReviewToGame from './forms/AddReviewToGame'
import RemovePlannedGame from './forms/RemovePlannedGame'
import RemovePlanGameTable from './RemovePlanGameTable'


function AdminGamePanel() {
  
  const [update, setupdate] = useState(false)

    const {data,loading,error} = useHttp(URLConstants.URL_FINISHED_GAMES,update)
    

  return (      
    <>
    <h2>Hry</h2>
    <div className='row justify-content-center' style={{marginTop: '2em'}}>
        {loading && <LoadingPanel />}
        {data && <>
           <div className='row justify-content-center'>
             <div className='col-xl-4'><AddFinishedGame games={data} setupdate={setupdate} /></div>
             <div className='col-xl-4'>
             <AddReviewToGame  games={data} setupdate={setupdate} />

              </div>
             <div className='col-xl-4'><RemovePlanGameTable games={data} setupdate={setupdate} /></div>
          </div> 

          
        </>}
        {error && <h6>Error</h6>  }
    </div>
    <div className='row justify-content-center' style={{marginTop: '2em'}}>


    </div>
    <hr />
    </>
  )
}

export default AdminGamePanel