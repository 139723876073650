
import React, { useContext, useRef, useState } from 'react'
import AuthContext from '../../../store/auth-context';
import { objectNewGameFilled } from '../../../utils/Utils';
import * as URLConstants from '../../../utils/Constants'
import { useNavigate } from 'react-router';




function AddFinishedGame({games,setupdate}) {
    const reviewInputRef = useRef();
    const nameInputRef = useRef();
    const scoreInputRef = useRef();
    const idInputRef = useRef();
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const [enteredScore, setEnteredScore] = useState("")
    const [enteredReview, setEnteredReview] = useState("")
    const [enteredId, setenteredId] = useState(0)
    const [enteredGame, setenteredGame] = useState('')
    const [errorMsg, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')  
    const [enteredType, setenteredType] = useState(1)

  

    const nameInputChangeHandler = event => {
        setenteredGame(event.target.value);
    };

    const scoreInputChangeHandler = event => {
      setEnteredScore(event.target.value);
  };

    const idInputChangeHandler = event => {
      setenteredId(event.target.value);
  };

  const reviewInputChangeHandler = event => {
    setEnteredReview(event.target.value);
};

    function handleChange(event){

        setenteredType(event.target.value);
    }


    const formSubmissionHandler = event => {
        event.preventDefault();

        const enteredId = idInputRef.current.value
        const enteredScore = Number(scoreInputRef.current.value) 
        const enteredName = nameInputRef.current.value
        const enteredReview = reviewInputRef.current.value
        const enteredTypeVal = enteredType

        const objToSend = {
          id: enteredId,
          name: enteredName,
          game_order: getLastGameOrder(games, enteredTypeVal),
          id_type:1,
          score: enteredScore,
          review: enteredReview
        }

        if(enteredScore < 0 || enteredScore > 100){
            setErrorMessage("Hodnocení musí být v rozsahu 0-100");
            return;
        }
     

        if(objectNewGameFilled(objToSend)){
      
          setErrorMessage("");
          const token = 'Bearer ' + authCtx.token;

          fetch(URLConstants.URL_GAMES,
          {
            method: 'POST',
            body: JSON.stringify(objToSend),
            headers: {
              'Content-Type':'application/json',
              'Authorization': token
            }
          }
          ).then(res => {
            if(res.ok) {
              return res.json().then((data) => {
                  setOkMessage(data.message)
                  setupdate(previousState => !previousState)
              })
            }else{
              if(res.status == 401){
                navigate("/login")
              }
              return res.json().then((data) => {
              
              })
            }
          });
        }else{
          setErrorMessage("Povinné pole nevyplněna");
        }
 
    };

  return (
      <>
    <h5>Přidat dohranou hru</h5>
    <form onSubmit={formSubmissionHandler}>
        <label className='d-block' htmlFor='name'>Id hry</label>
        <input ref={idInputRef} type="number" id='name' onChange={idInputChangeHandler} value={enteredId} />
        <label className='d-block' htmlFor='name'>Název hry</label>
        <input ref={nameInputRef} type="text" id='name' onChange={nameInputChangeHandler} value={enteredGame} />

        <label className='d-block' htmlFor='score'>Hodnocení</label>
        <input ref={scoreInputRef} type="number" placeholder='0-100' id='score' onChange={scoreInputChangeHandler} value={enteredScore} />

        <label className='d-block' htmlFor='name'>Recenze</label>
        <textarea ref={reviewInputRef}  onChange={reviewInputChangeHandler} value={enteredReview} rows="4" cols="50" name="comment" form="usrform" />
        


        <button className='d-block btn btn-success mt-3'>Přidat</button>
    </form>
    <p style={{color: 'red'}}>{errorMsg}</p>
    <p style={{color: 'green'}}>{okMessage}</p>
    </>
  )
}

function getLastGameOrder(games, id_type){
  let max = 0;
  const filteredGames =  games.filter(item => item.id_type === id_type)
  for (let index = 0; index < filteredGames.length; index++) {
    const element = filteredGames[index];
    if(element.game_order > max){
      max = element.game_order
    }
  }
  max++;
  return max; 
}

export default AddFinishedGame