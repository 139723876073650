import Content from "./layout/Content";



import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import NavigationMenu from './layout/NavigationMenu';
import SocialNavMenu from './layout/SocialNavMenu';
import Logo from "./layout/Logo";

import SideBar from "./sidebar/SideBar";

import HeaderMobile from "./layout/header/HeaderMobile";

function App() {


  return (
    <>
    <BrowserView>
    <div>
        <div className="row ">  
            <div className="col-2" id="sticky-sidebar" style={{background: 'rgb(40, 40, 40)',minHeight: '100vh'}} >
                <div className="sticky-top" style={{minHeight: '100vh'}}>
                 <Logo />
                <NavigationMenu />
                <SideBar />
                <div className="row bottom justify-content-center" style={{display: 'block'}}>
                <SocialNavMenu />
                </div>
                </div>
            </div>
            <div className="col-10 order-2 bg" id="main" style={{padding: '2em'}}   >
            <Content />
            </div>
        </div>
    </div>
    </BrowserView>


    <MobileView>
    <HeaderMobile />
        <div className="row m-2">  
            <Content />
        </div>
  
    </MobileView>
    </>


      );

}


/**
 *     <div className='row' style={{height: '100vh'}} >
<div className='col-xl-2 '> 
  
</div>
<div className='col-xl-8' style={{paddingTop: '3em'}}> 
    
</div>
<div className='col-xl-2' style={{marginTop: '3em'}}> 
   
</div>




</div>
 */
export default App;
