
import motherboard from '../../img/pcsetup/motherboard.png'
import grafika from '../../img/pcsetup/grafika.png'
import hdd from '../../img/pcsetup/hdd.png'
import pccase from '../../img/pcsetup/case.png'
import ram from '../../img/pcsetup/ram.png'
import ssd from '../../img/pcsetup/ssd.png'
import zdroj from '../../img/pcsetup/zdroj.png'
import processor from '../../img/pcsetup/processor.png'

import elgato_card from '../../img/pcsetup/elgato_card.webp'
import keyboard from '../../img/pcsetup/keyboard.webp'
import mic from '../../img/pcsetup/mic.webp'
import monitor from '../../img/pcsetup/monitor.webp'
import monitor2 from '../../img/pcsetup/monitor2.webp'
import mouse from '../../img/pcsetup/mouse.webp'
import sluchatka from '../../img/pcsetup/sluchatka.webp'
import webcam from '../../img/pcsetup/webcam.webp'
import ps4 from '../../img/pcsetup/PS4.webp'
import hori from '../../img/pcsetup/hori.webp'
import dual_charger from '../../img/pcsetup/dualcharger.webp'
import exthdd from '../../img/pcsetup/exthdd.webp'

export const setupData = [
    {name: 'SilentiunPC Signum SG1', img: pccase},
    {name: 'TUF GAMING B450M-PLUS', img: motherboard},
   {name: 'Ryzen 5 3600', img: processor},
   {name: 'Kingston HyperX Fury Black 16 GB DDR4 DIMM, 3200 MHz', img: ram},
   {name: '250 GB Samsung 860 EVO', img: ssd},
   {name: '1000 GB Seagate BarraCuda', img: hdd},
   {name: 'NVIDIA GeForce RTX 2060, 6144 MB GDDR6', img: grafika},
   {name: '600W Be quiet! System Power 9', img: zdroj},

]


export const setupDataOthers = [
    {name: 'Acer Nitro VG240YPbiip 24"', img: monitor},
    {name: 'ASUS VA24EHE 23,8"', img: monitor2},
   {name: 'Trust GXT 101 GAV', img: mouse},
   {name: 'Logitech G733 Lightspeed', img: sluchatka},
   {name: 'Logitech C920s PRO HD', img: webcam},
   {name: 'SPC Gear SM900', img: mic},
   {name: 'MSi Interceptor DS4100', img: keyboard},
   {name: 'Elgato Game Capture HD60 Pro', img: elgato_card},
   {name: ' HORI Tactical Assault Commander Pro V2', img: hori},
   {name: 'PlayStation 4', img: ps4},
   {name: 'Games Tower + Dual charger', img: dual_charger},
   {name: 'Externí disk Verbatim', img: exthdd},

]