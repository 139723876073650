import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HttpsRedirect from 'react-https-redirect';

import App from './App';
import { AuthContextProvider } from './store/auth-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HttpsRedirect>
  <AuthContextProvider>
  <React.StrictMode>

     <BrowserRouter basename="/">      
    <App />  
    </BrowserRouter>

  </React.StrictMode>
  </AuthContextProvider>
  </HttpsRedirect>
);

