import { Button } from 'bootstrap'
import { Dropdown } from 'bootstrap'
import React, { useContext, useState } from 'react'
import { Navbar, NavDropdown, NavItem } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import styles from './NavigationMenu.module.css'
import AuthContext from '../store/auth-context'



function NavigationMenu() {

    const authCtx  = useContext(AuthContext)

    const isLoggedIn = authCtx.isLoggedIn;


    const [gameOpen, setgameOpen] = useState(false)

    function toggleGameMenu(){
           if(gameOpen) {
               setgameOpen(false)
           }else{
               setgameOpen(true)
           }
    }

    function openGameMenu(){
            setgameOpen(true)   
     }

     function closeGameMenu(){
        setgameOpen(false)   
    }
 


      return (
        <Navbar collapseOnSelect expand="lg" className='flex-column'>
        <NavLink className={styles.navlink  + '  ms-auto' } to="/" >INFO</NavLink>
        <NavLink className={styles.navlink  + '  ms-auto' } to="/setup" >SETUP</NavLink>
        <button onClick={toggleGameMenu} className={styles.navlink_button + '  ms-auto'}>HRY</button>
        {gameOpen && <>
            <NavLink className={styles.navlink_smaller + '  ms-auto'} to="/games">DOHRANÉ</NavLink>
            <NavLink className={styles.navlink_smaller + '  ms-auto'} to="/gamesplanned">PLÁNOVANÉ</NavLink>
      
        </> }
    
        <NavLink className={styles.navlink + '  ms-auto'} to="/movie">FILMOVÉ RECENZIČKY</NavLink>
        <NavLink className={styles.navlink + '  ms-auto'} to="/tvshows">SERIALOVÉ RECENZIČKY</NavLink>
        <NavLink className={styles.navlink + '  ms-auto'} to="/books">KNÍŽNÍ RECENZIČKY</NavLink>
        {isLoggedIn && (
              <NavLink className={styles.navlink + '  ms-auto'} to="/admin">Admin</NavLink>
        )}
        </Navbar>    
      )
 

}

export default NavigationMenu