import React from 'react'
import * as URLConstants from '../../utils/Constants'
import useHttp from '../../hooks/use-http'
import { useState } from 'react'
import AddPlannedGame from './forms/AddPlannedGame'
import RemovePlannedGame from './forms/RemovePlannedGame'
import RemovePlanGameTable from './RemovePlanGameTable'



function AdminPlanGamesPanel() {

    
    const [update, setupdate] = useState(false)

    const {data,loading,error} = useHttp(URLConstants.URL_GAMES,update)

  return (
    <>
    <h2>Plánované hry</h2>
    {data && <>
    <div className='row justify-content-center'>
             <div className='col-xl-4'><AddPlannedGame games={data} setupdate={setupdate} /></div>
       
              <div className='col-xl-4'>              
              <RemovePlanGameTable games={data.filter(game => game.id_type === 2).sort((a, b) => a.name.localeCompare(b.name))} setupdate={setupdate} />
              </div>   
                      
          </div> 
          </>
          }
    </>
  )
}

export default AdminPlanGamesPanel