
import nibiru from '../img/games/nibiru.webp'
import polda from '../img/games/polda.webp'
import cesta from '../img/games/cesta.webp'


export function getImageUrl(gameId){
    if(gameId === 203){
        return cesta
    }
    if(gameId === 220){
        return nibiru
    }
    if(gameId === 67997){
        return polda
    }

    return 'https://static-cdn.jtvnw.net/ttv-boxart/' + gameId +'_IGDB-150x200.jpg'
}


export function getLaswWordFromstring(sentence){
    var n = sentence.split(" ");
    return n[n.length - 1];
}

export function removeLaswWordFromstring(str){
    var lastIndex = str.lastIndexOf(" ");
    
    return str.substring(0, lastIndex);
}

export function objectNewGameFilled(game){
    if(game.name === '') return false
    if(game.id === 0) return false
    if(game.id_type === '') return false

    return true
}

export function getScoreTest(number){

    if(number === 0){
        return "0/10"
    }
  
    const numberToDisplay = number/10;


    return numberToDisplay + "/10"
}



