import React, { useEffect, useState } from 'react'
import LoadingPanel from '../component/LoadingPanel';
import useHttp from '../hooks/use-http';
import SideBarList from './SideBarList';
import * as URLConstants from '../utils/Constants'

function SideBar() {
  
  const [daySchedule, setdaySchedule] = useState([])
  const [weekendSchedule, setWeekendSchedule] = useState([])
    
  const {data,loading} = useHttp(URLConstants.URL_SCHEDULE)

  useEffect(() => {

    if(data){
      const normalDays = data.filter((item) => item.id_type === 1);
      const weekDays = data.filter((item) => item.id_type === 2);
  
      setdaySchedule(normalDays);
      setWeekendSchedule(weekDays);   
    }
 

  }, [data])
  


  return (<>
    
      {loading && <LoadingPanel />}
    
      <div className='row' style={{marginLeft: '1em',marginRight: '0.5em',marginTop: '2em',paddingBottom: '0.5em',border: '1px solid white',borderRadius: '10px'}}>
      <h5 className='text-white text-center'>Rozvrh</h5>
     
        <div className='col-xl-6'>
        <h6 className='text-white'>Všední dny</h6>
        {weekendSchedule && <> 
          <SideBarList items = {weekendSchedule} />
          
           </>}
           </div>
           <div className='col-xl-6'>
        {daySchedule && <> 
       
        <h6 className='text-white'>Víkendy</h6>
          <SideBarList items = {daySchedule} />
        </>}
        </div>
      </div>
    </>
  )
}

export default SideBar