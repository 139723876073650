import React from 'react'
import RemoveGamePlanItem from './RemoveGamePlanItem'

function RemovePlanGameTable({games, setupdate}) {


    console.log(games)



  return (

    <>
    <h5>Odebrat</h5>
    <table className='table table-striped ' style={{ color: 'white' }}>
  
    <thead>
      <tr>
        <th scope="col">Hra</th>
     
        <th scope="col">Akce</th>
      </tr>
    </thead>
    <tbody>

    {games &&  games.map((game, index) => {
      return <RemoveGamePlanItem game={game} key={index} setUpdate={setupdate} />
})
    
    }

</tbody>
        </table>
    
        </>
    
  )
}

export default RemovePlanGameTable