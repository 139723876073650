import React from 'react'
import fb from '../img/socials/facebook.png'
import ig from '../img/socials/ig.png'
import ln from '../img/socials/linkedin.png'
import tiktok from '../img/socials/tiktok.png'
import twitter from '../img/socials/twitter.png'
import youtube from '../img/socials/youtube.png'
import './SocialNavMenu.css'

function SocialNavMenu() {
  return (
          <div >
            <a href="https://www.facebook.com/ondra.blahut/" target='_blank' className="socials2">
              <img className="img-socials2" src={fb} alt=""/>
            </a>
            <a href="https://www.instagram.com/sniper.ondra/" target='_blank' className="socials2">
              <img className="img-socials2" src={ig} alt=""/>
            </a>
            <a href="https://www.youtube.com/channel/UCYXKDij6AOOqetmO1rx-Trw" target='_blank' className="socials2">
              <img className="img-socials2" src={youtube} alt=""/>
            </a>
            </div>
        
  )
}

export default SocialNavMenu