import React from 'react'
import { Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styles from './HeaderMobile.module.css'
import logo from '../../img/sniperondralogo.png'
import SocialNavMenu from '../SocialNavMenu'

import btnImg from '../../img/button.png'
import NavigationMenuMobile from '../NavigationMenuMobile'


function HeaderMobile() {

  function myFunction() {
    var x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  return (<>

<div class="row align-items-center h-100" style={{background: 'rgb(40,40,40)'}}>
      <div class="col mx-auto text-white">
        <img srcSet={logo} alt="" style={{width: '60px'}} />
        </div>
      <div class="col">
          <div class="float-right">
            <img onClick={myFunction} srcSet={btnImg} className={styles.menu_button +  ' m-3'} alt="" style={{float: 'right', width: '30px',height: 'auto'}} />
          </div>
      </div>
    <div id="myLinks">
     <NavigationMenuMobile />
    </div>
</div>

  </>
  
  )
}

/**
 *   <div id="myLinks">
      <a href="#news">News</a>
      <a href="#contact">Contact</a>
      <a href="#about">About</a>
    </div>
 *  <img srcSet={logo} alt="" style={{maxWidth: '250px',margin:'1em'}} />
        <Navbar collapseOnSelect expand="lg" className='justify-content-center navmenu'>
        <NavLink className={styles.navlink} to="/" >MOJE BIO</NavLink>
        <NavLink className={styles.navlink} to="/hry">HRY</NavLink>
        <NavLink className={styles.navlink} to="/sestava">SESTAVA</NavLink>
        <NavLink className={styles.navlink} to="/socials">SOCIALS</NavLink>
        </Navbar>        
 */

export default HeaderMobile