import React, { useContext, useState } from 'react'
import useHttp from '../../../hooks/use-http';
import AuthContext from '../../../store/auth-context';
import * as URLConstants from '../../../utils/Constants'
import { useNavigate } from 'react-router';
function RemoveMovieForm({movies,setupdate}) {

    const [enteredGameId, setenteredGameId] = useState(1)
    const [gameType, setGameType] = useState(1)
    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const [errorMsg, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')  

   

    const formSubmissionHandler = event => {
        event.preventDefault();
  
    
        if(enteredGameId !==0){

            const game = { 
            };
      
            
            const token = 'Bearer ' + authCtx.token;
            fetch(URLConstants.URL_MOVIES_DELETE + enteredGameId,
            {
              method: 'DELETE',
              game,
              headers: {
                'Content-Type':'application/json',
                'Authorization': token
              }
            }
            ).then(res => {
              if(res.status === 401){
                navigate("/login")
              }
              if(res.ok) {
                return res.json().then((data) => {
                    setOkMessage(data.message)
                    setupdate(previousState => !previousState)
                })
              }else{
                if(res.status == 401){
                  navigate("/login")
                }
                return res.json().then((data) => {
                  setErrorMessage(data.message)
                })
              }
            });
        }
        setenteredGameId(0) 
    };


  function handleChange(event){
    setenteredGameId(event.target.value);
  }

    return (
      <div className='col-xl-6'>
      <h5>Odebrat filmovou recenzi</h5>
              <form onSubmit={formSubmissionHandler}>


                  <label htmlFor="gametypes" className='d-block'>Hra</label>
          <select className='d-block my-2' name="gametypes" id="gametype"  onChange={handleChange} >
            {movies && <>
          {movies.map((item,index) => (
            <option key={index} value={item.id}>{item.name}</option>
            ))}</>}
          
          </select>
  
          <button type="submit" className="btn btn-danger">Odebrat</button>
      </form>
      <p style={{color: 'red'}}>{errorMsg}</p>
    <p style={{color: 'green'}}>{okMessage}</p>
      </div>
    )
}

export default RemoveMovieForm