import forest from '../../img/movies/forest.webp'
import venom from '../../img/movies/venom.webp'
import no_photo from '../../img/no_photo.png'


export function getMovieById(img){
    if(img)
    return "/storage/app/public/images/" + img
    else
    return no_photo


}